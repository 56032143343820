import emoji from 'emojilib'

const people = {}

Object.entries(emoji.lib)
  .filter(
    ([name, value]) =>
      value.category === 'people' &&
      value.fitzpatrick_scale &&
      !value.keywords.includes('hand') &&
      !value.keywords.includes('hands') &&
      !value.keywords.includes('finger') &&
      !value.keywords.includes('fingers') &&
      ![
        'ear',
        'leg',
        'nose',
        'ok_hand',
        'selfie',
        'pray',
        'foot',
        'writing_hand',
        'crossed_fingers'
      ].includes(name)
  )
  .forEach(([key, value]) => {
    people[key] = { ...value, name: key }
  })

const getRandomEmoji = () =>
  people[
    Object.keys(people)[Math.floor(Math.random() * Object.keys(people).length)]
  ]

export { people, getRandomEmoji }
