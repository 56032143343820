import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description = '', meta = [], title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      meta={[
        { name: 'author', content: 'http://ingo.bingo' },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `keywords`,
          content:
            'radio, amsterdam, live, dj, stream, set, talks, video, platform, radio radio, club, support'
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        { property: `og:url`, content: 'https://live.radioradio.radio' },
        {
          property: `og:image`,
          content: `https://live.radioradio.radio${require('@media/og.png')}`
        },
        {
          property: `og:image:width`,
          content: `1200`
        },
        {
          property: `og:image:height`,
          content: `630`
        }
      ].concat(meta)}
    />
  )
}

export default SEO
