import FriendlyWebSocket from '@utils/FriendlySocket'
import { api } from '@components/api'
import { getRandomEmoji } from '@utils/people'

const room = 'rara'
const socket = new FriendlyWebSocket({
  url: 'wss://rara-donate.herokuapp.com/r/rara',
})

const emoji = getRandomEmoji().name

const join = () => {
  socket.send({ type: 'join', room })
}

const like = () => {
  socket.send({ type: 'like', room })
}

const update = () => {
  socket.send({ type: 'update', room, data: { emoji } })
}

const sendMessage = (message: any) => {
  socket.send({
    type: 'chat',
    room,
    chatmessage: message.content,
    chatname: message.name,
  })
}

const updateAdmin = (
  pass: string,
  values: { [key: string]: string | boolean }
) => {
  socket.send({ type: 'admin', pass, room, settings: values })
}

const handleMessage = (data) => {
  if (!data || !data.type) {
    console.error('Unknown message')
    return
  }
  switch (data.type) {
    case 'liked':
      console.log('LIKED')
      api.getState().addHeart()
      return
    case 'update':
      console.log('UPDATE')
      const {
        settings: {
          color,
          title,
          live,
          streamURL,
          testStreamURL,
          rerunStreamURL,
          rerun,
          socialLinks,
          showLinksOffline,
          showDonations,
        },
        clients,
      } = data.data

      if (color && live) {
        document.documentElement.style.setProperty('--main-color', color)
      }
      if (!live) {
        document.documentElement.style.setProperty('--main-color', '#DCDCDC')
      }

      api.setState({
        color,
        title,
        live,
        streamURL:
          streamURL && streamURL !== ''
            ? streamURL
            : 'https://stream.radioradio.radio/radioradio/donate/playlist.m3u8',
        testStreamURL:
          testStreamURL && testStreamURL !== ''
            ? testStreamURL
            : 'https://stream.radioradio.radio/radioradio/test/playlist.m3u8',
        rerunStreamURL:
          rerunStreamURL && rerunStreamURL !== ''
            ? rerunStreamURL
            : 'https://stream.radioradio.radio/radioradio/offline/playlist.m3u8',
        socialLinks: socialLinks ? socialLinks : [],
        rerun,
        streamcount: clients.length,
        clients,
        showLinksOffline: !!showLinksOffline,
        showDonations: !!showDonations,
      })
      return
    case 'chatmessage':
      console.log('MESSAGE')
      api.getState().addMessage({
        content: data.data.chatmessage,
        name: data.data.chatname,
      })
      return
    case 'donated':
      console.log('DONATED!')
      api.setState((s) => ({
        donateCount: s.donateCount + 1,
      }))
      api.getState().addMessage({
        isDonation: true,
        name:
          (data.data && data.data.Donation && data.data.Donation.Name) ||
          'Anonymous',
        content: 'Just donated!',
      })
      return
    default:
      console.error('Unknown message')
      return
  }
}

const initSocket = () => {
  socket.on('open', () => {
    console.log('hello world')
    join()
    update()
  })
  socket.on('message', (data) => {
    handleMessage(JSON.parse(data))
  })

  socket.connect()
}

const visibilityCheck = () => {
  let hidden = 'hidden'
  let visibilityChange = 'visibilitychange'

  if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  function handleVisibilityChange() {
    if (document[hidden]) {
      api.setState({ listenForHearts: false })
    } else {
      api.setState({ listenForHearts: true })
    }
  }

  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}
if (typeof window !== 'undefined') visibilityCheck()

export { initSocket, join, update, like, updateAdmin, sendMessage }
