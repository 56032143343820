import React from 'react'
import { Input as StyledInput } from 'theme-ui'
import { animated } from 'react-spring'

const Input = React.forwardRef(
  ({ field, form, type = 'text', ...props }, ref) => (
    <StyledInput
      type={type}
      {...field}
      sx={{
        p: 3,
        borderRadius: 0,
        border: '1px solid black',
        fontFamily: 'body',
        '&:nth-of-type(1)': {
          borderBottom: 'none'
        },
        '&:focus': {
          outline: 'none',
          boxShadow: 'inset 0 0 0 1px black'
        },
        '&::placeholder': {
          color: 'black',
          opacity: 0.6
        }
      }}
      {...props}
      ref={ref}
    />
  )
)

const AnimatedInput = animated(Input)

export const NoBorderInput = React.forwardRef((props, ref) => (
  <AnimatedInput
    ref={ref}
    {...props}
    sx={{
      fontFamily: 'body',
      border: 'none',
      borderRadius: 0,
      p: 0,
      '&:focus': {
        outline: 'none'
      },
      '&::placeholder': {
        color: 'black',
        opacity: 0.6
      }
    }}
  />
))

export const AdminInput = props => (
  <Input
    {...props}
    sx={{
      p: 2,
      fontFamily: 'body',
      borderRadius: 0,
      border: '1px solid black',
      '&:focus': {
        outline: 'none',
        boxShadow: 'inset 0 0 0 1px black'
      },
      '&::placeholder': {
        color: 'black',
        opacity: 0.6
      }
    }}
  />
)

export default Input
