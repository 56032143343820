import { create } from 'zustand'
import produce from 'immer'
import pull from 'lodash/pull'

const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api)

export const MAX_HEARTS = 100

const [useStore, api] = create(
  immer((set) => ({
    strength: 0,
    hearts: [],
    inactiveHearts: new Array(MAX_HEARTS).fill(0).map((_, i) => i),
    clients: [],
    color: '#DCDCDC',
    streamcount: 0,
    donateCount: 0,
    showDonations: true,
    title: '',
    streamURL:
      'https://stream.radioradio.radio/radioradio/donate/playlist.m3u8',
    testStreamURL:
      'https://stream.radioradio.radio/radioradio/test/playlist.m3u8',
    rerunStreamURL:
      'https://stream.radioradio.radio/radioradio/offline/playlist.m3u8',
    socialLinks: [],
    showLinksOffline: false,
    listenForHearts: true,
    live: false,
    rerun: false,
    links: undefined,
    messages: [],
    addMessage: (message: { content: string; name: string }) => {
      set((state) => {
        if (state.messages.length > 80) {
          state.messages.shift()
        }
        state.messages.push(message)
      })
    },
    addHeart: () => {
      set((state) => {
        if (state.listenForHearts && state.hearts.length < MAX_HEARTS) {
          const inactive = state.inactiveHearts.shift()
          state.hearts.push(inactive)
        }
      })
    },
    clearHeart: (id: string) => {
      set((state) => {
        pull(state.hearts, id)
        state.inactiveHearts.push(id)
      })
    },
  }))
)

export { useStore, api }
