/** @jsx jsx */
import { forwardRef } from 'react'
import {
  jsx,
  Button as StyledButton,
  ButtonProps,
  Close,
  LinkProps,
} from 'theme-ui'

const ButtonBase = (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <StyledButton
    sx={{
      appearance: 'none',
      fontFamily: 'inherit',
      display: 'inline-block',
      minWidth: '6.25em',
      border: '1px solid black',
      borderRadius: '3em',
      bg: 'var(--main-color)',
      color: 'black',
      p: '0.625em 1em',
      fontSize: '1rem',
      textDecoration: 'none',
      textAlign: 'center',
      cursor: 'pointer',
      margin: '1rem',
      transition: 'background-color 0.3s ease-out',
      '@media not all and (hover: none)': {
        '&:hover': {
          bg: 'black',
          color: 'var(--main-color)',
        },
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 1px black',
      },
      svg: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: '#fff',
        width: '1.25em',
        height: '1.25em',
        mr: '0.25em',
      },
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    }}
    type="button"
    {...props}
    {...ref}
  />
)

export const Button = forwardRef(ButtonBase)

export const LinkButton = (
  props: LinkProps,
  ref: React.Ref<HTMLButtonElement>
) => (
  <a
    sx={{
      appearance: 'none',
      fontFamily: 'inherit',
      display: 'inline-block',
      minWidth: '6.25em',
      border: '1px solid black',
      borderRadius: '3em',
      bg: 'var(--main-color)',
      color: 'black',
      p: '0.625em 1em',
      fontSize: '1rem',
      textDecoration: 'none',
      textAlign: 'center',
      cursor: 'pointer',
      margin: '1rem',
      transition: 'background-color 0.3s ease-out',
      '@media not all and (hover: none)': {
        '&:hover': {
          bg: 'black',
          color: 'var(--main-color)',
          fontStyle: 'normal',
        },
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 1px black',
      },
      svg: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: '#fff',
        width: '1.25em',
        height: '1.25em',
        mr: '0.25em',
      },
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    }}
    {...props}
    {...ref}
  />
)

export const CloseButton = (props) => (
  <Button
    sx={{
      position: 'absolute',
      minWidth: 0,
      background: 'none',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: 0,
      p: 2,
      top: 1,
      right: 1,
      svg: { fill: 'black', m: 0 },
      '&:hover': {
        background: 'none',
        opacity: 0.6,
        transform: 'scale(1.1)',
        color: 'black',
      },
      '&:focus': {
        outline: 'none',
        transform: 'scale(1.1)',
        boxShadow: 'none',
      },
    }}
    {...props}
  >
    <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0014 1.28167L18.7197 0L10.0002 8.71856L1.28069 0L-0.000976562 1.28167L8.71854 10.0002L-0.000976562 18.7188L1.28069 20.0005L10.0002 11.2819L18.7197 20.0005L20.0014 18.7188L11.2819 10.0002L20.0014 1.28167Z" />
    </svg>
  </Button>
)
