/** @jsx jsx */
import React from 'react'
import { Heading, Grid, jsx } from 'theme-ui'
import { ellipsis } from 'polished'
import { keyframes } from '@emotion/core'
import { useStore } from '@components/api'
import Marquee from '@components/Marquee'
import AbsolutLogo from '@components/AbsolutLogo'
import KornuitLogo from '@components/KornuitLogo'
import shallow from 'zustand/shallow'

const blink = keyframes`
25% {
  opacity: 100%;
}
30% {
  opacity: 0%;
}
70% {
  opacity: 0%;
}
75% {
  opacity: 100%;
}
`

export const SmallStreamCounter = ({ admin = false }: { admin?: boolean }) => {
  const streamcount = useStore((s) => s.streamcount)
  return streamcount === 0 ? null : (
    <Grid
      sx={{
        display: admin ? 'grid' : ['grid', 'none'],
        gridGap: 2,
        gridTemplateColumns: 'min-content min-content',
        px: 2,
        alignItems: 'center',
        svg: {
          height: '1em',
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="var(--main-color)"
        viewBox="0 0 33 21"
      >
        <path d="M16.563 0C7.303 0 .818 10.188.818 10.188S5.54 20.377 16.563 20.377c11.114 0 15.746-10.188 15.746-10.188S25.919 0 16.563 0zm0 16.671a6.442 6.442 0 01-6.482-6.482 6.442 6.442 0 016.482-6.483 6.442 6.442 0 016.483 6.482 6.442 6.442 0 01-6.483 6.483z" />
        <path d="M19.342 10.188a2.778 2.778 0 11-5.557 0 2.778 2.778 0 115.557 0z" />
      </svg>
      <div>{streamcount}</div>
    </Grid>
  )
}

export const Header = ({
  admin = false,
  test,
}: {
  admin?: boolean
  test?: boolean
}) => {
  const { title, rerun, live } = useStore(
    ({ title, rerun, live }) => ({ title, rerun, live }),
    shallow
  )

  return (
    <Grid
      sx={{
        position: 'sticky',
        bg: 'var(--main-color)',
        width: '100%',
        top: '0',
        left: '0',
        gridColumnGap: 0,
        gridRowGap: 0,
        gridTemplateColumns: ['1fr max-content', '50% 1fr max-content'],
        gridTemplateAreas: [
          `'logo sponsors' 'title title'`,
          `'title logo sponsors'`,
        ],
        alignItems: 'stretch',
        zIndex: 100,
      }}
    >
      <Grid
        sx={{
          bg: 'black',
          color: 'var(--main-color)',
          gridGap: 2,
          p: 2,
          color: 'var(--main-color)',
          alignItems: 'center',
          gridArea: 'title',
          gridTemplateColumns: admin
            ? ['min-content 1fr min-content', 'min-content 1fr auto']
            : ['min-content 1fr min-content', 'min-content auto'],
          borderRight: ['none', '1px solid var(--main-color)'],
          borderTop: ['1px solid var(--main-color)', 'none'],
        }}
      >
        <div
          sx={{
            border: '1px solid var(--main-color)',
            color: 'var(--main-color)',
            borderRadius: 16,
            display: 'flex',
            alignItems: 'center',
            pl: live ? 2 : 3,
            py: 1,
            pr: 3,
            svg: {
              height: '0.6em',
              mr: 2,
              ml: 1,
              animation: live ? `${blink} linear 1.25s infinite` : 'none',
            },
          }}
        >
          {live && (
            <svg
              viewBox="0 0 10 10"
              fill="var(--main-color)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" />
            </svg>
          )}
          <div>{live ? 'Live' : rerun ? 'RERUN' : 'Offline'}</div>
        </div>

        <Heading sx={{ ...ellipsis() }}>
          {live || rerun || test ? title : ''}
        </Heading>

        <SmallStreamCounter admin={admin} />
      </Grid>

      <Marquee
        fill={{
          src: require('@media/logo-inverted.svg'),
          width: 132 * 0.5,
          height: 100 * 0.5,
        }}
      />

      {/* <Grid
        sx={{
          gridArea: 'sponsors',
          gridTemplateColumns: 'max-content max-content',
          borderLeft: '1px solid var(--main-color)',
          px: 3,
          bg: 'black',
          color: 'var(--main-color)',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          gridColumnGap: 3,
          gridRowGap: 0
        }}
      >
        <KornuitLogo
          fill="var(--main-color)"
          sx={{ height: ['1.4em', '1.6em'] }}
        />
        <AbsolutLogo
          fill="var(--main-color)"
          sx={{ height: ['1.2em', '1.4em'] }}
        />
      </Grid> */}
    </Grid>
  )
}
