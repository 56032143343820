import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'

const containerStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  transform: 'translate3d(0, 0, 0)'
}

export default class Marquee extends PureComponent {
  static propTypes = {
    fill: PropTypes.shape({
      src: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number
    }),
    rate: PropTypes.number,
    children: PropTypes.node
  }

  static defaultProps = {
    rate: 25
  }

  constructor(props) {
    super(props)

    this._lastTick = 0
    this._offset = 0
    this.state = {
      copies: 2,
      width: 0
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.measure)
    if (!this.props.idle) this.start()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.measure)
    this.stop()
  }

  componentDidUpdate() {
    this.props.idle ? this.stop() : this.start()
  }

  onContainerRef = node => {
    if (this.props.innerRef) this.props.innerRef(node)
    if (node === null) return
    this._container = node
  }

  onWrapperRef = node => {
    if (node === null) return
    this._wrapper = node
  }

  update = ts => {
    const { rate, fill } = this.props

    const diff = this._lastTick ? (ts - this._lastTick) / 1000 : 0
    let offset = this._offset - rate * diff

    if (-offset >= fill.width) {
      offset = offset % fill.width
    }

    this._offset = Math.round(offset * 100) / 100
    this._lastTick = ts

    if (this._wrapper) {
      this._wrapper.style.transform = `translate3d(${this._offset}px, 0, 0)`
    }

    this._tick = window.requestAnimationFrame(this.update)
  }

  start() {
    if (this._tick) return
    this._tick = window.requestAnimationFrame(this.update)
  }

  stop() {
    window.cancelAnimationFrame(this._tick)
    this._tick = null
  }

  onResize = () => {
    if (!this._container) return

    const bounds = this._container.getBoundingClientRect()
    const width = bounds.width

    this.setState(
      {
        width,
        copies: Math.ceil(width / this.props.fill.width) + 1
      },
      () => this.props.onResize && this.props.onResize(bounds)
    )
  }

  renderChildren() {
    const { offset, copies } = this.state
    const style = {
      transform: `translate3d(${offset}px, 0, 0)`,
      willChange: 'transform',
      backgroundImage: `url(${this.props.fill.src})`,
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundRepeat: 'repeat-x',
      height: `${this.props.fill.height}px`,
      width: `${this.props.fill.width * copies}px`
    }

    return <div ref={this.onWrapperRef} style={{ ...style }} />
  }

  render() {
    const { className = '', style = {} } = this.props

    return (
      <Measure innerRef={this.onContainerRef} onResize={this.onResize}>
        {({ measureRef }) => (
          <div
            className={className}
            style={{ ...containerStyle, ...style }}
            ref={measureRef}
          >
            {this.renderChildren()}
          </div>
        )}
      </Measure>
    )
  }
}
